import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  FormControlLabel,
} from "@mui/material";

import UsersMenuPermissions from "./UsersMenuPermissions";
import { makeStyles } from "@mui/styles";

const roleFunctions = [
  {
    id: 1,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Fabrics",
  },

  {
    id: 2,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Fabrics QR Code Generator",
  },
  {
    id: 3,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Cartons",
  },
  {
    id: 4,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Cartons QR Code Generator",
  },
  {
    id: 7,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Reports",
  },
  {
    id: 8,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Users",
  },
  {
    id: 9,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Tracking Purposes",
  },
  {
    id: 10,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Tracking Locations",
  },
  {
    id: 11,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Unit of Measurements",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    user_status,
    setUserStatus,
    item,
    handleInputChange,
    error,
    helperText,
    setRoleFunctionPush,
    roleFunctionPush,
    setAppUserStatus,
    app_user_status,
  } = props;

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  const handleChangeAppPermission = (event) => {
    setAppUserStatus(event.target.value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Name"
              autoFocus
              size="small"
              className={classes.textField}
              error={error.name}
              helperText={helperText.name}
              defaultValue={item ? item.name : null}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="user_id"
              variant="outlined"
              required
              fullWidth
              id="user_id"
              label="Employee Id"
              autoFocus
              className={classes.textField}
              size="small"
              error={error.user_id}
              helperText={helperText.user_id}
              defaultValue={item ? item.employee_id : null}
              onChange={handleInputChange}
              inputProps={{ readOnly: !item ? false : true }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              variant="outlined"
              fullWidth
              id="email"
              label="Email Address"
              autoFocus
              size="small"
              className={classes.textField}
              error={error.email}
              helperText={helperText.email}
              defaultValue={item ? item.email : null}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              fullWidth
              id="phone_no"
              label="Phone Number"
              autoFocus
              size="small"
              className={classes.textField}
              error={error.phone_no}
              helperText={helperText.phone_no}
              defaultValue={item ? item.phone_no : null}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              label="Password"
              multiline
              required
              fullWidth
              size="small"
              variant="outlined"
              error={error.password}
              className={classes.textField}
              helperText={helperText.password}
              defaultValue={item ? item.user_password : null}
              onChange={handleInputChange}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 14, fontWeight: "bold" }}
              >
                User Status
              </FormLabel>
              <RadioGroup
                aria-label="status"
                name="status1"
                defaultValue={item ? item.user_status : user_status}
                onChange={handleChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 12 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="D"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 12 }}>Deactivate</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 14, fontWeight: "bold" }}
              >
                App Permissions
              </FormLabel>
              <RadioGroup
                aria-label="status"
                name="status1"
                defaultValue={item ? item.app_user_status : app_user_status}
                onChange={handleChangeAppPermission}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 12 }}>Yes</Typography>}
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={<Typography style={{ fontSize: 12 }}>No</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel
                component="legend"
                style={{ fontSize: 16, fontWeight: "bold" }}
              >
                Permissions
              </FormLabel>
              <div style={{ marginTop: 10 }}>
                <UsersMenuPermissions
                  roleFunctions={item ? item.permissions : roleFunctions}
                  setRoleFunctionPush={setRoleFunctionPush}
                  roleFunctionPush={roleFunctionPush}
                  item={item}
                />
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

import { makeStyles } from "@mui/styles";
import Logo from "../../assets/logo.svg";

export default makeStyles((theme) => ({
  appBar: {
    display: "flex",
    flexDirection: "row",
    maxHeight: 64,
    background: `linear-gradient(to right, #0f2027, #203a43, #2c5364)`,
    // background: `linear-gradient(to right, #34e89e, #0f3443)`,
    // background: `linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)`,
    // background: `linear-gradient(to right, #0f0c29, #302b63, #24243e)`,
    // background: `linear-gradient(to right, #159957, #155799)`,
    overflow: "hidden",
    boxShadow: "1px 1px 2px grey",
  },
  appBarItem: {
    height: "100%",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  logoContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: 64,
  },
}));

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Dashboard from "../../pages/Dashboard";
import Materials from "../../modules/materials";
import Users from "../../modules/settings/users";

const Index = () => {
  return (
    <div style={{ display: "flex", paddingLeft: 50, paddingRight: 50 }}>
      <Switch>
        <Route path="/app/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/app/materials">
          <Materials />
        </Route>
        <Route exact path="/app/users">
          <Users />
        </Route>
        <Redirect exact from="/app" to="/app/dashboard" />
      </Switch>
    </div>
  );
};

export default withRouter(Index);

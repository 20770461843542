import React, { useEffect, useState } from "react";

import {
  AppBar,
  Grid,
  Avatar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  Icon,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Box,
} from "@mui/material";
import { styled, alpha, useTheme } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SettingsIcon from "@mui/icons-material/Settings";
import CategoryIcon from "@mui/icons-material/Category";
import SummarizeIcon from "@mui/icons-material/Summarize";

//css
import useStyles from "./styles";

//logo
import Logo from "../../assets/logo.png";

//router
import { Link } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { moduleList } from "../../redux/actions/modules";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    minWidth: 250,
    minHeight: -10,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
        color: "#1d2357",
        fontWeight: "500",
      },
    },
  },
}));

const Index = () => {
  var classes = useStyles();
  const dispatch = useDispatch();

  const [getId, setId] = useState("");
  const [alignment, setAlignment] = React.useState("Materials");
  const [selected, setSelected] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const module = useSelector((state) => state.module);
  const { modules } = module;

  console.log("moduleData", alignment);

  const handleAlignment = (event, newAlignment) => {
    console.log("newAlignment", newAlignment);
    setAlignment(newAlignment);
  };

  useEffect(() => {
    dispatch(moduleList());
  }, [dispatch]);

  const modulesTree = (function (modules, root) {
    var o = {};
    modules &&
      modules.forEach(function (a) {
        a.children = o[a.id] && o[a.id].children;
        o[a.id] = a;
        o[a.parent_menu] = o[a.parent_menu] || {};
        o[a.parent_menu].children = o[a.parent_menu].children || [];
        o[a.parent_menu].children.push(a);
      });
    return o[root];
  })(modules, 0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetId = (item) => {
    setId(item);
    console.log("item", item);
  };

  return (
    <AppBar id="app-bar" position="fixed" className="not-scrolled">
      <Grid container className={classes.appBar}>
        <Grid item xs={3} className={classes.appBarItem}>
          <div className={classes.logoContainer}>
            <img src={Logo} alt="Logo" className={classes.logo} />
          </div>
        </Grid>
        <Grid item xs={9} className={classes.appBarItem}>
          <div
            style={{
              height: 64,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{ marginRight: 20, display: "flex", flexDirection: "row" }}
            >
              {modulesTree
                ? modulesTree.children.map((item) => {
                    return (
                      <div
                        style={{
                          minWidth: 90,
                          marginRight: 10,
                        }}
                      >
                        <ToggleButton
                          value={item.menu_caption}
                          component={item.menu_link === "/" ? Typography : Link}
                          to={{
                            pathname: `/app${item.menu_link}`,
                            state: {
                              item_icon: item.menu_icon,
                            },
                          }}
                          aria-expanded={open ? "true" : undefined}
                          disableElevation
                          onClick={(event) => {
                            if (item.children !== undefined) {
                              handleClick(event);
                              handleSetId(item);
                            }
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textTransform: "none",
                            height: 50,
                            backgroundColor: "#F7FBFF",
                          }}
                        >
                          <Icon
                            style={{
                              fontSize: 18,
                            }}
                          >
                            {item.menu_icon}
                          </Icon>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 2,
                            }}
                          >
                            <Typography
                              style={{
                                wordWrap: "break-word",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              {item.menu_caption}
                            </Typography>
                            {item.children ? (
                              <ArrowDropDownIcon fontSize="small" />
                            ) : null}
                          </div>
                        </ToggleButton>
                        {item.children !== undefined && getId.id === item.id ? (
                          <div style={{}}>
                            <Popover
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              elevation={0}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              PaperProps={{
                                style: {
                                  backgroundColor: "transparent",
                                  boxShadow:
                                    "11px 16px 28px -8px rgba(0,0,0,0.32)",
                                  borderRadius: 5,
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  mt: "10px",
                                  "&::before": {
                                    backgroundColor: "white",
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    width: 12,
                                    height: 12,
                                    top: -6,
                                    transform: "rotate(45deg)",
                                    left: "calc(50% - 6px)",
                                  },
                                }}
                              />
                              <div
                                style={{
                                  background: "white",
                                  borderRadius: 5,
                                  minWidth: 150,
                                }}
                              >
                                {item.children
                                  ? item.children.map((item, index) => {
                                      return (
                                        <MenuItem
                                          onClick={handleClose}
                                          component={Link}
                                          to={`/app${item.menu_link}`}
                                          key={index}
                                          style={{ fontSize: 14 }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 22,
                                              marginRight: 10,
                                            }}
                                          >
                                            {item.menu_icon}
                                          </Icon>
                                          {item.menu_caption}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </div>
                            </Popover>
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>

            <Typography
              variant="caption"
              style={{ wordWrap: "break-word", marginRight: 10 }}
            >
              Shahibuzzaman shishir
            </Typography>
            <Avatar>H</Avatar>
            <IconButton aria-label="delete" size="small">
              <ArrowDropDownIcon />
            </IconButton>
            {/* <IconButton aria-label="delete" size="small">
              <SettingsIcon style={{ fontSize: 18 }} />
            </IconButton> */}
          </div>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Index;

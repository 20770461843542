import axios from "axios";
import {
  MODULE_REQUEST,
  MODULE_SUCCESS,
  MODULE_FAIL,
  MODULE_GLOBAL_REQUEST,
  MODULE_GLOBAL_SUCCESS,
  MODULE_GLOBAL_FAIL,
} from "../../constants/modules";
import API_URL from "../../../utils/api";

export const moduleList = () => async (dispatch) => {
  try {
    dispatch({
      type: MODULE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "applycation/json",
        Accpet: "applycation/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/appmenus`, config);

    console.log("moduleData", data);

    dispatch({
      type: MODULE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MODULE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const moduleGlobalAction = () => async (dispatch) => {
  try {
    dispatch({
      type: MODULE_GLOBAL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "applycation/json",
        Accpet: "applycation/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/appmenus`, config);

    console.log("globalData", data);

    dispatch({
      type: MODULE_GLOBAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MODULE_GLOBAL_FAIL,

      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { Box, TextField, InputAdornment, Grid, Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import useMediaQuery from "@mui/material/useMediaQuery";

//components
import Popup from "./Popup";
import EditPopup from "./EditPopup";
import DeletePopup from "./DeletePopup";
import Notification from "../../../utils/notifications";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, searchValues) {
  const stabilizedThis = searchValues.fn(array).map((el, id) => [el, id]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    width: "15%",
    align: "right",
    label: "Name",
  },
  {
    id: "employee_id",
    numeric: true,
    disablePadding: false,
    label: "Employee Id",
    width: "15%",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    width: "15%",
  },
  {
    id: "phone_no",
    numeric: true,
    disablePadding: false,
    label: "Phone No.",
    width: "15%",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "10%",
  },
  {
    id: "app_user_status",
    numeric: false,
    disablePadding: false,
    label: "App Permission",
    width: "15%",
  },
  {
    id: 10,
    numeric: false,
    disablePadding: false,
    label: "Actions",
    width: "15%",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minWidth: "100%",
        }}
      >
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              top: 57,
              width: `${headCell.width}`,
              height: 40,
              display: "flex",
              alignItems: "center",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleSearch } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: "80%",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  pagination: {
    "& .MuiTablePagination-spacer": {},
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function EnhancedTable(props) {
  const {
    users,
    permissions,
    userData,
    app_menu_id,
    notify,
    setNotify,
    setUserCreatedSuccess,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(9);
  const [searchValues, setSearchValues] = React.useState({
    fn: (users) => {
      return users;
    },
  });

  const [item, setItem] = React.useState("");
  const [id, setId] = React.useState("");

  //
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

  console.log("userData", userData);
  console.log("app_menu_id", app_menu_id);

  //
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (users) => {
        if (target.value === "") return users;
        else
          return users.filter(
            (x) =>
              (x.first_name
                ? x.first_name.toLowerCase().includes(target.value)
                : null) ||
              (x.user_status
                ? x.user_status.toLowerCase().includes(target.value)
                : null) ||
              (x.email ? x.email.toLowerCase().includes(target.value) : null) ||
              (x.phone_no ? x.phone_no.includes(target.value) : null) ||
              (x.user_id ? x.user_id.includes(target.value) : null) ||
              (x.employee_id
                ? x.employee_id.toString().includes(target.value)
                : null)
          );
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //permissions

  const insertPermission = permissions.length
    ? permissions[0].user_role_function_insert === 1
      ? true
      : false
    : null;
  console.log("Insert", insertPermission);

  const updatePermission = permissions.length
    ? permissions[0].user_role_function_update === 1
      ? true
      : false
    : null;
  console.log("Update", updatePermission);

  const deletePermission = permissions.length
    ? permissions[0].user_role_function_delete === 1
      ? true
      : false
    : null;
  console.log("Delete", deletePermission);

  const printPermission = permissions.length
    ? permissions[0].user_role_function_print === 1
      ? true
      : false
    : null;
  console.log("Print", printPermission);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", marginBottom: 20 }}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={3} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Icon style={{ fontSize: 22, marginTop: 20, color: "#13292A" }}>
                group
              </Icon>
              <h3 style={{ marginLeft: 5, color: "#13292A" }}>Users </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -30,
                fontSize: 11,
                marginLeft: 25,
                color: "#13292A",
              }}
            >
              <p>Settings</p>
              <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
              <p>Users</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={8} align="center">
            <div style={{ marginTop: 25 }}>
              <div style={{ flexGrow: 1, width: "80%" }}>
                <TextField
                  label="Search"
                  id="outlined-size-small"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={4} align="right">
            <div style={{ marginTop: 25 }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ fontSize: 12, color: "#13292A" }}
                style={{ minWidth: 150, minHeight: 40, marginBottom: 10 }}
                onClick={() => setOpenPopup(true)}
                // disabled={!insertPermission}
              >
                Add New
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: "90vw", minHeight: "60vh" }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users ? users.length : null}
            />
            <TableBody>
              {console.log("searchValue", searchValues)}
              {stableSort(users, getComparator(order, orderBy), searchValues)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${id}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      style={{
                        display: "flex",
                        width: "100%",
                        minHeight: "4.4vh",
                        wordBreak: "break-word",
                      }}
                    >
                      <TableCell
                        id={labelId}
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.employee_id}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                          wordBreak: "break-all",
                        }}
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.phone_no}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.user_status === "A" ? "Active" : "Deactivate"}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          width: "15%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.app_user_status === "N" ? "No" : "Yes"}
                      </TableCell>
                      <TableCell align="left" style={{ width: "15%" }}>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              setOpenEditPopup(true);
                              setItem(row);
                              console.log(row);
                            }}
                            // disabled={!updatePermission}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              setId(row.id);
                              setOpenDeletePopup(true);
                              setItem(row);
                            }}
                            // disabled={!deletePermission}
                          >
                            <DeleteIcon
                              fontSize="small"
                              style={{
                                fill: "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        <BootstrapTooltip
                          title={
                            <div>
                              <p>Created By : {row.created_by}</p>
                              <p>Created Date : {row.created_date}</p>
                              <p>Modified By : {row.modified_by}</p>
                              <p>Modified Date : {row.modified_date}</p>
                            </div>
                          }
                        >
                          <IconButton aria-label="info" size="small">
                            <InfoIcon
                              fontSize="small"
                              style={{ fill: "	#ffcc00" }}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[9, 15, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          style={{
            justifyContent: "flex-end",
            display: "flex",
          }}
        />
      </Paper>
      <Popup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        userData={userData}
        app_menu_id={app_menu_id}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
      />
      <EditPopup
        setOpenEditPopup={setOpenEditPopup}
        openEditPopup={openEditPopup}
        item={item}
        userData={userData}
        app_menu_id={app_menu_id}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
      />
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
      />
    </Box>
  );
}

import axios from "axios";
import {
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
} from "../../constants/permissions";
import API_URL from "../../../utils/api";

export const permissionAction = (user_id, app_menu_id) => async (dispatch) => {
  try {
    dispatch({
      type: PERMISSION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      `${API_URL}/api/userrolefunctions/permission`,
      {
        user_id: user_id,
        menu_caption: app_menu_id,
      },
      config
    );

    dispatch({
      type: PERMISSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { userDeleteAction, userList } from "../../../redux/actions/user";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeletePopup = (props) => {
  const {
    openDeletePopup,
    setOpenDeletePopup,
    id,
    item,
    setNotify,
    setUserCreatedSuccess,
  } = props;

  const handleClose = () => {
    setOpenDeletePopup(false);
  };

  const dispatch = useDispatch();

  const userDelete = useSelector((state) => state.userDelete);
  const { success } = userDelete;

  // const roleFunctionDelete = useSelector((state) => state.roleFunctionDelete);

  // const { response } = roleFunctionDelete;

  // console.log("res", response);
  const deleteHandler = () => {
    dispatch(userDeleteAction(id, setNotify));
    setOpenDeletePopup(false);
    setTimeout(() => {
      dispatch(userList());
      setNotify({
        isOpen: true,
        message: "User Deleted.",
        type: "success",
      });
    }, 2000);
  };

  React.useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
    }
  }, [success]);

  return (
    <div>
      <Dialog
        open={openDeletePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {" "}
          <p style={{ fontSize: 14 }}>
            Are you sure you want to remove{" "}
            <strong style={{ fontSize: 16 }}>{`${item.name}`}</strong>?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteHandler}
            color="secondary"
            variant="contained"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePopup;

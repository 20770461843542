import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import store from "./components/redux/store";
import { BrowserRouter as Router } from "react-router-dom";

import Themes from "./components/utils/themes";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={Themes.default}>
      <Router>
        <CssBaseline />
        <App />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

import React from "react";

//mui
import { Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: 150,
    right: theme.spacing(2),
  },
}));

function Notification(props) {
  const classes = useStyle();
  const { notify, setNotify } = props;

  return (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      elevation={6}
      variant="filled"
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={() => setNotify({ isOpen: false })}
    >
      <Alert severity={notify.type}>{notify.message}</Alert>
    </Snackbar>
  );
}
export default Notification;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

//mui
import { makeStyles, useTheme, styled } from "@mui/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  FormControlLabel,
  lighten,
  FormControl,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

//mui-icons

import DeleteIcon from "@mui/icons-material/Delete";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";

//components
import DeletePopup from "./DeletePopup";

//redux
import { useDispatch } from "react-redux";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  divider: {
    background: "white",
    marginTop: theme.spacing(2),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "app_menu",
    numeric: true,
    disablePadding: false,
    label: "Menus",
  },
  {
    id: "permissions",
    numeric: true,
    disablePadding: false,
    label: "Permissions",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    searchBox,
    SearchClose,
    SearchOpen,
    setOpenPopup,
    handleChange,
    searchValues,
    setSearchValues,
    handleSearch,
    setAddNew,
    onRowAdd,
    handleBulkDelete,
    insertPermission,
    printPermission,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleBulkDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <Tooltip title='Search'>
            <IconButton aria-label='Search' onClick={SearchOpen}>
              <SearchIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Add New Row">
            <IconButton
              aria-label="Add New Row"
              onClick={onRowAdd}
              // disabled={!insertPermission}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton aria-label="Download" disabled={!printPermission}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = (theme) => ({
  root: {
    width: "100%",
    fontSize: "0.1em",
  },
  table: {
    minWidth: 750,
  },
  tableBody: {
    minHeight: 400,
  },
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const EnhancedTable = (props) => {
  const {
    roleFunctions,

    setRoleFunctionPush,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [searchBox, setSearchBox] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [rowdata, setRowData] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [userRoleFunctions, setUserRoleFunctions] = React.useState([]);

  const [toggleButton, setToggleButton] = React.useState(false);

  const [checked, setChecked] = React.useState({
    insert: false,
    update: false,
    delete: false,
    print: false,
  });

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleAddEvent = () => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var product = {
      id: id,
      user_role_id: "",
      app_menu_id: "",
      user_role_function_insert: false,
      user_role_function_update: false,
      user_role_function_delete: false,
      user_role_function_print: false,
    };

    setProducts((currentArray) => [...currentArray, product]);
  };

  const newArray = (roleFunctions) => {
    let data = roleFunctions.map((e) => {
      if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 0 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 0 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 0 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert == 1 &&
        e.user_role_function_update == 1 &&
        e.user_role_function_delete == 1 &&
        e.user_role_function_print == 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      }
      return e;
    });
    setUserRoleFunctions(data);
  };

  console.log("userRoleFunctions", userRoleFunctions);

  const handleAddProductTable = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var value = JSON.parse(item.value.toLowerCase());
    console.log("iddd5", item);

    var newProducts = products.map(function (product) {
      for (var key in product) {
        console.log("prr", products);
        if (key === item.name && product.id === item.id) {
          product[key] = value;
        }
      }

      return product;
    });
    setProducts(newProducts);
    var marge = products.concat(userRoleFunctions);
    setToggleButton(true);
    setRoleFunctionPush({
      data: marge,
    });
    console.log("xyx", marge);
  };

  const handleProductTable = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var id = parseInt(item.id, 10);
    var value = JSON.parse(item.value.toLowerCase());

    var newProducts = userRoleFunctions.map((product) => {
      console.log("j999", product);
      for (var key in product) {
        if (key === item.name && product.id == id) {
          product[key] = value;
          // product['crud_value'] =
          //   product.insert + product.update + product.delete + product.print;
        }
      }
      return product;
    });
    setUserRoleFunctions(newProducts);
    setToggleButton(true);
    var marge = userRoleFunctions.concat(newProducts);
    setRoleFunctionPush({
      data: marge,
    });
    console.log("xyx12", marge);
  };

  const handleRowDel = (product) => {
    var index = products.indexOf(product);
    products.splice(index, 1);
    console.log("pro", products);
    setProducts([...products]);
  };

  const [searchValues, setSearchValues] = React.useState({
    fn: (roles) => {
      return roles;
    },
  });

  const [role_status, setRoleStatus] = React.useState("A");

  const [id, setId] = React.useState("");

  console.log("item", id);

  const handleChange = (event) => {
    setRoleStatus(event.target.value);
  };

  const dispatch = useDispatch();

  var cloneArray = JSON.parse(JSON.stringify(roleFunctions));

  console.log("abcd", roleFunctions);

  useEffect(() => {
    newArray(roleFunctions);
  }, [dispatch, roleFunctions]);

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (userRoleFunctions) => {
        if (target.value == "") return userRoleFunctions;
        else
          return userRoleFunctions.filter((x) =>
            x.role_name.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const SearchClose = (value) => {
    setSearchBox(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userRoleFunctions.map((n) => n.id);
      setSelected(newSelecteds);
      setSearchBox(false);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSearchBox(false);
  };

  console.log("selected", selected);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const onAddRowClick = () => {
    setRowData(
      rowdata.concat({ username: "", email: "", gender: "", phone: "" })
    );
  };

  // const handleChange = (prop) => (event) => {
  //   setSearchValues({ ...searchValues, [prop]: event.target.value });
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      userRoleFunctions ? userRoleFunctions.length : null - page * rowsPerPage
    );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={userRoleFunctions ? userRoleFunctions.length : null}
            />
            <TableBody>
              {userRoleFunctions && userRoleFunctions.length !== 0 ? (
                userRoleFunctions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, id) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${id}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          align="right"
                          style={{ width: "27%" }}
                          padding="checkbox"
                        >
                          <FormControl
                            margin="dense"
                            size="small"
                            fullWidth="true"
                            variant="outlined"
                          >
                            <TextField
                              name="user_id"
                              variant="outlined"
                              fullWidth
                              id="user_id"
                              size="small"
                              defaultValue={row.menu_caption}
                              inputProps={{
                                readOnly: true,
                                style: { fontSize: 12 },
                              }}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontSize: 12,
                            width: "50%",
                          }}
                          padding="checkbox"
                        >
                          <FormControlLabel
                            value={!row.user_role_function_insert}
                            control={
                              <Checkbox
                                checked={row.user_role_function_insert}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_insert"
                                size="small"
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Insert</span>}
                          />
                          <FormControlLabel
                            value={!row.user_role_function_update}
                            control={
                              <Checkbox
                                checked={row.user_role_function_update}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_update"
                                size="small"
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Update</span>}
                          />
                          <FormControlLabel
                            value={!row.user_role_function_delete}
                            control={
                              <Checkbox
                                checked={row.user_role_function_delete}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_delete"
                                size="small"
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Delete</span>}
                          />
                          <FormControlLabel
                            value={!row.user_role_function_print}
                            control={
                              <Checkbox
                                checked={row.user_role_function_print}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_print"
                                size="small"
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Print</span>}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow className={classes.tableBody}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <p style={{ textAlign: "center" }}>No Data Available!</p>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
      />
      {/* <Notification notify={notify} setNotify={setNotify} /> */}
    </div>
  );
};

export default EnhancedTable;

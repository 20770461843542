import {
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
} from "../../constants/permissions";

export const permissionReducer = (state = { permissions: [] }, action) => {
  switch (action.type) {
    case PERMISSION_REQUEST:
      return { loading: true, permissions: [] };
    case PERMISSION_SUCCESS:
      return { loading: false, permissions: action.payload };
    case PERMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import {
  MODULE_REQUEST,
  MODULE_SUCCESS,
  MODULE_FAIL,
  MODULE_GLOBAL_REQUEST,
  MODULE_GLOBAL_SUCCESS,
  MODULE_GLOBAL_FAIL,
} from "../../constants/modules";

export const moduleReducerSidebar = (state = { modules: [] }, action) => {
  switch (action.type) {
    case MODULE_REQUEST:
      return { loading: true, modules: [] };
    case MODULE_SUCCESS:
      return { loading: false, modules: action.payload };
    case MODULE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const moduleReducerGlobal = (state = { globalModules: [] }, action) => {
  switch (action.type) {
    case MODULE_GLOBAL_REQUEST:
      return { loading: true, globalModules: [] };
    case MODULE_GLOBAL_SUCCESS:
      return { loading: false, globalModules: action.payload };
    case MODULE_GLOBAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./FormValidation";
import { userCreateAction, userList } from "../../../redux/actions/user";

const roleFunctions = [
  {
    id: 1,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Fabrics",
  },

  {
    id: 2,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Fabrics QR Code Generator",
  },
  {
    id: 3,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Cartons",
  },
  {
    id: 4,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Cartons QR Code Generator",
  },
  {
    id: 7,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Reports",
  },
  {
    id: 8,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Users",
  },
  {
    id: 9,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Tracking Purposes",
  },
  {
    id: 10,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Tracking Locations",
  },
  {
    id: 11,
    user_role_function_insert: 0,
    user_role_function_update: 0,
    user_role_function_delete: 0,
    user_role_function_print: 0,
    menu_caption: "Unit of Measurements",
  },
];

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DIALOG_TITLE = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>group</Icon>
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DIALOG_CONTENT = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const DIALOG_ACTIONS = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    userData,
    app_menu_id,
    notify,
    setNotify,
    setUserCreatedSuccess,
  } = props;

  const [user_status, setUserStatus] = React.useState("A");
  const [app_user_status, setAppUserStatus] = React.useState("N");
  const [roleFunctionPush, setRoleFunctionPush] = React.useState({
    data: roleFunctions,
  });

  const userCreate = useSelector((state) => state.userCreate);
  const { success, userCreates } = userCreate;

  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("user_id" in fieldValues)
      temp.user_id = fieldValues.user_id ? "" : "This field is required.";

    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const name = values.name;
  const phone_no = values.phone_no;
  const email = values.email;
  const employee_id = values.user_id;
  const password = values.password;

  const post_data = {
    name: name,
    employee_id: employee_id,
    email: email,
    phone_no: phone_no,
    password: password,
    permissions: roleFunctionPush.data,
    user_status: user_status,
    app_user_status: app_user_status,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(userCreateAction(post_data));
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(userList());
        setNotify({
          isOpen: true,
          message: "User Created.",
          type: "success",
        });
      }, 2000);

      resetForm();
    }
  };

  console.log("success", userCreates);

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
    }
  }, [success]);

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openPopup}>
      <DIALOG_TITLE
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </DIALOG_TITLE>
      <DIALOG_CONTENT dividers>
        <InputForm
          setUserStatus={setUserStatus}
          user_status={user_status}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          roleFunctionPush={roleFunctionPush}
          setRoleFunctionPush={setRoleFunctionPush}
          app_user_status={app_user_status}
          setAppUserStatus={setAppUserStatus}
        />
      </DIALOG_CONTENT>
      <DIALOG_ACTIONS style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DIALOG_ACTIONS>
    </Dialog>
  );
};

export default Popup;

import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { userList } from "../../../redux/actions/user";
import { permissionAction } from "../../../redux/actions/permissions";
import Notification from "../../../utils/notifications";

const Body = (props) => {
  const dispatch = useDispatch();
  const { userData } = props;
  const [app_menu_id, setAppMenuId] = useState("Users");
  const [userCreatedSuccess, setUserCreatedSuccess] = useState(false);
  const user = useSelector((state) => state.user);
  const { users } = user;
  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    dispatch(userList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData, app_menu_id]);

  console.log("notify", notify);
  console.log("userCreatedSuccess", userCreatedSuccess);

  return (
    <div style={{ width: "100%", marginTop: 20 }}>
      {users.length ? (
        <div>
          <Table
            users={users}
            permissions={permissions}
            userData={userData}
            app_menu_id={app_menu_id}
            notify={notify}
            setNotify={setNotify}
            setUserCreatedSuccess={setUserCreatedSuccess}
          />
          {userCreatedSuccess && (
            <Notification notify={notify} setNotify={setNotify} />
          )}
        </div>
      ) : (
        <div
          style={{
            minHeight: "80vh",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Body;

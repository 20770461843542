import { combineReducers } from "redux";

import { loginReducer } from "./auth";
import { moduleReducerSidebar, moduleReducerGlobal } from "./modules";
import { permissionReducer } from "./permissions";

import {
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
} from "./user";

const rootReducer = combineReducers({
  auth: loginReducer,
  module: moduleReducerSidebar,
  moduleGlobal: moduleReducerGlobal,
  permission: permissionReducer,
  //users
  user: userListReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
});

export default rootReducer;

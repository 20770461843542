import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    maxWidth: "100vw",
    minHeight: "100vh",
    overflowX: "hidden",
    margin: 0,
  },
  body: {
    marginTop: 60,
    minHeight: `calc(100vh - 60px)`,
    backgroundColor: `#F3F8FF`,
  },
}));
